<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCV" scrollable persistent max-width="620px">
      <v-card class="radius-card" v-if="profile">
        <v-toolbar dense flat>
          <span class="headline">View CV</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-container class="pa-0">
            <div class="__container pa-5">
              <div class="__full_w __top d-flex">
                <div class="__left">
                  <h1>{{ profile.nama_lengkap }}</h1>
                  <h3>{{ bidang.data.nama }}</h3>
                  <div class="__line"></div>
                  <h3>Contact</h3>
                  <div class="__contact">
                    <section>
                      <v-icon>mdi-email</v-icon> {{ profile.email }}
                    </section>
                    <section>
                      <v-icon>mdi-cellphone</v-icon> {{ profile.no_hp }}
                    </section>
                    <section>
                      <v-icon>mdi-map-marker</v-icon> {{ kota.data.nama_kota }}
                    </section>
                  </div>
                </div>
                <div class="__half">
                  <v-avatar color="white" size="150" class="min-img">
                    <v-img :src="avatar" class="img-fit" alt="asd"></v-img>
                  </v-avatar>
                </div>
              </div>
              <div class="__about">
                <h3>About</h3>
                <p>
                  {{ profile.profile_singkat }}
                </p>
              </div>

              <h3>Work Experience</h3>
              <div class="__contact">
                <section
                  class="d-flex"
                  v-for="item in experiences.data"
                  :key="item.id"
                >
                  <div class="mr-2">
                    <v-icon>mdi-office-building-marker-outline</v-icon>
                  </div>
                  <div>
                    <section class="px-3 rounded purple white--text">
                      {{ item.periode }}
                    </section>
                    <section>
                      {{ item.posisiJabatan }} di {{ item.namaPerusahaan }}
                    </section>
                  </div>
                </section>
              </div>
              <h3 class="mt-3">Education</h3>
              <div class="__contact">
                <section
                  class="d-flex"
                  v-for="item in educational.data"
                  :key="item.id"
                >
                  <div class="mr-2">
                    <v-icon>mdi-school</v-icon>
                  </div>
                  <div>
                    <section class="px-3 rounded purple white--text">
                      {{ item.tahunLulus }}
                    </section>
                    <section>
                      {{ item.tingkat }} di {{ item.namaLembagaPendidikan }}
                    </section>
                  </div>
                </section>
              </div>
              <div class="__temp_skill purple lighten-4 pa-3 d-flex rounded">
                <div class="mr-5">
                  <b>Skill</b>
                </div>
                <div class="d-flex">
                  <div
                    class="mr-5"
                    v-for="(item, idx) in profile.skill_kompetensi"
                    :key="idx"
                  >
                    <v-icon>mdi-star-check</v-icon> <br />
                    {{ item }}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue"
            outlined
            rounded
            @click="downloadCv"
            v-if="!loading"
          >
            <v-icon class="mr-2">mdi-cloud-download</v-icon>
            Download Cv</v-btn
          >
          <v-btn
            color="blue"
            outlined
            rounded
            @click="downloadCv"
            v-if="loading"
          >
            <v-progress-circular
              indeterminate
              size="25"
              class="mr-2"
            ></v-progress-circular>
            Loaidng</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "viewCV",
  components: {},
  props: ["dialogCV", "profile"],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      env: state => state.API_URL,
      educational: state => state.psycholog.educational,
      experiences: state => state.psycholog.experiences,
      dummy: state => state.dummy
    }),
    bidang() {
      let data = null;
      if (this.profile) {
        let item = this.profile.relationships;
        let item2 = this.profile.data_bidang;
        if (item) {
          for (let i = 0; i < item.length; i++) {
            if (item[i].bidang) {
              data = item[i].bidang;
            }
          }
        } else if (item2) {
          data = item2.nama;
        }
      }
      return data;
    },
    kota() {
      let data = null;
      if (this.profile) {
        let item = this.profile.relationships;
        let item2 = this.profile.data_kota;
        if (item) {
          for (let i = 0; i < item.length; i++) {
            if (item[i].kota1) {
              data = item[i].kota1;
            }
          }
        } else if (item2) {
          data = item2.nama_kota;
        }
      }
      return data;
    },
    avatar() {
      let data = null;
      if (this.profile) {
        if (this.profile.photo_profile) {
          data = `${this.env}/upload/photo_profile/${this.profile.id}/${this.profile.photo_profile}`;
        } else if (this.profile.data) {
          data = `${this.env}/upload/photo_profile/${this.profile.data.id}/${this.profile.data.photo_profile}`;
        } else {
          data = this.dummy;
        }
      }
      return data;
    },
    firstname() {
      let data = null;
      if (this.profile.id) {
        if (this.profile.nama_lengkap) {
          data = this.profile.nama_lengkap.split(" ")[0];
        }
      } else if (this.profile.data) {
        if (this.profile.data.nama_lengkap) {
          data = this.profile.data.nama_lengkap.split(" ")[0];
        }
      }
      console.log(data);
      console.log("hehe");
      return data;
    },

    lastname() {
      let data = null;
      if (this.profile.id) {
        if (this.profile.nama_lengkap) {
          data = this.profile.nama_lengkap
            .split(" ")
            .splice(1)
            .toString()
            .replace(/,/g, " ");
        }
      } else if (this.profile.data) {
        if (this.profile.data.nama_lengkap) {
          data = this.profile.data.nama_lengkap
            .split(" ")
            .splice(1)
            .toString()
            .replace(/,/g, " ");
        }
      }
      return data;
    }
  },
  methods: {
    downloadCv() {
      this.loading = true;
      this.$store.dispatch("psycholog/generateCv").then(data => {
        axios({
          url: data.data,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${this.profile.username}.pdf`);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
        this.loading = false;
        setTimeout(() => this.$store.dispatch("psycholog/deleteCv"), 2000);
      });
    }
  }
};
</script>

<style scoped>
.margin_bottom {
  margin-bottom: 20px;
}
.__container {
  height: 842px;
  width: 600px;
  background: url("../../../../assets/img/cv background.png") no-repeat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}
.__half {
  position: relative;
}
.min-img {
  margin-left: 42px;
  margin-top: -5px;
}
.__top {
  margin-top: 70px;
}
.__full_w {
  width: 100%;
}
.__left {
  width: 60%;
}
.__line {
  width: 100%;
  height: 5px;
  background: purple;
  margin-bottom: 10px;
}
.__about {
  margin-top: 10px;
  height: 150px;
}
/* .phill {
  padding: 5px;
  border-radius: 5px;
  background: rgb(223, 78, 223);
} */
</style>
